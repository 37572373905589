<template>
  <div>
    <b-row>
      <StockInfoPanels
        :storeName="selectedStore.name"
        :forDate="tempGlobalForDate"
      >
      </StockInfoPanels>
      <b-col :sm="12">
        <StockAuditItemsPreviewTable :items="items">
        </StockAuditItemsPreviewTable>
      </b-col>
      <b-col>
        <v-textarea
          name="receiving-notes"
          :label="$t('NOTES')"
          auto-grow
          rows="1"
          prepend-icon="mdi-comment"
          v-model="tempNotes"
        ></v-textarea>
      </b-col>
    </b-row>
    <div class="mt-3">
      <b-button
        :disabled="false"
        class="mr-3"
        variant="primary"
        @click="$emit('on-save', payload)"
        >{{ $t("SAVE") }}</b-button
      >
      <b-button
        class="mr-3"
        variant="outline-primary"
        @click="updateWizardStep(2)"
        >{{ $t("BACK") }}</b-button
      >
    </div>
  </div>
</template>
<style scoped>
tr.previewTr th {
  font-size: 18px !important;
}
</style>
<script>
import { mapGetters, mapState } from "vuex";

import StockInfoPanels from "@/modules/stock/components/StockInfoPanels.vue";
import StockAuditItemsPreviewTable from "@/modules/stock/components/audits/StockAuditItemsPreviewTable.vue";

import _ from "lodash";
export default {
  name: "StepStockAuditPreview",
  inheritAttrs: false,
  props: {
    firstLoader: {
      type: Boolean,
      default: true
    },
    doGetItems: {
      type: Boolean,
      default: true
    },
    wizardStep: {
      type: Number,
      default: 1
    },
    globalForDate: {
      type: String,
      default: ""
    },
    notes: {
      type: String,
      default: ""
    },
    items: {
      type: Array,
      default: function() {
        return [];
      }
    },
    selectedStore: {
      type: Object,
      default: function() {
        return {};
      }
    }
  },
  data() {
    return {};
  },
  components: { StockInfoPanels, StockAuditItemsPreviewTable },
  beforeMount() {},
  mounted() {},
  computed: {
    ...mapGetters(["isLoadingStock", "isLoadingSharedStore", "currentUser"]),
    ...mapState({
      errors: state => state.auth.errors
    }),
    tempFirstLoader: {
      get: function() {
        return this.firstLoader;
      },
      set: function(newValue) {
        this.$emit("update:firstLoader", newValue);
      }
    },
    tempGlobalForDate: {
      get: function() {
        return this.globalForDate;
      },
      set: function(newValue) {
        this.$emit("update:globalForDate", newValue);
      }
    },
    tempNotes: {
      get: function() {
        return this.notes;
      },
      set: function(newValue) {
        this.$emit("update:notes", newValue);
      }
    },
    tempItems: {
      get: function() {
        return this.items;
      },
      set: function(newValue) {
        this.$emit("update:items", newValue);
      }
    },
    payload() {
      let vm = this;
      let payload = {};
      payload.items = vm.tempItems;
      payload.storeId = vm.selectedStore.id;
      payload.note = vm.tempNotes;
      payload.forDate = vm.tempGlobalForDate;
      return payload;
    }
  },
  watch: {},
  methods: {
    updateWizardStep(wizardStep) {
      this.$emit("update:wizardStep", wizardStep);
    },
    transformUrlParams(params) {
      let apiParams =
        "?" +
        Object.keys(params)
          .map(key => key + "=" + params[key])
          .join("&");
      return apiParams;
    },
    handleInput(prop, value) {
      this.$emit("stepperInput", {
        prop: prop,
        value: value
      });
    }
  }
};
</script>
