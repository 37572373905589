<template>
  <div>
    <b-row>
      <StockInfoPanels
        v-if="!firstLoader"
        :storeName="selectedStore.name"
        :forDate="tempGlobalForDate"
      >
      </StockInfoPanels>
      <b-col :sm="12">
        <v-card-title>
          <v-text-field
            v-model="search"
            clearable
            append-icon="mdi-magnify"
            :label="$t('SEARCH')"
            single-line
            hide-details
          ></v-text-field>
        </v-card-title>
        <v-data-table
          :headers="headers"
          :items="items"
          :search="search"
          :items-per-page="20"
          :footer-props="{
            showFirstLastPage: true,
            itemsPerPageOptions: rowsPerPageItems,
            firstIcon: 'mdi-arrow-collapse-left',
            lastIcon: 'mdi-arrow-collapse-right'
          }"
        >
          <template v-slot:item.name="{ item }">
            <strong>{{ item.name }}</strong>
          </template>
          <template v-slot:item.itemTypeName="{ item }">
            <small>{{ item.itemTypeName | capitalize }} </small>
          </template>
          <template v-slot:item.warehouseName="{ item }">
            <small>{{ item.warehouseName | capitalize }} </small>
          </template>

          <template v-slot:item.audit.currentStock="{ item }">
            <v-text-field
              v-model="item.audit.currentStock"
              :label="$t('CURRENT_STOCK')"
              hide-details
              type="number"
              :step="item.unitNumberType == 2 ? 1 : 0.001"
              min="0"
              @blur="filterCurrentStock(item)"
            >
              <template v-slot:append> ({{ item.unitShortName }}) </template>
            </v-text-field>
          </template>
          <template slot="body.append">
            <tr class="previewTr">
              <th colspan="100%">
                <div class="row">
                  <div class="col-sm-12 col-md">
                    <span
                      v-for="(itemType, index) in itemTypesCount"
                      v-bind:key="'itemType-' + index"
                    >
                      {{ index | capitalize }}: {{ itemType }},
                    </span>
                    Total: {{ _.size(tempItems) }}
                  </div>
                  <div class="col-sm-12 col-md text-right">
                    {{ $t("AUDIT_FOOD_COST") }}:
                    {{ sumTotalFoodCost }}
                  </div>
                </div>
              </th>
            </tr>
          </template>
        </v-data-table>
      </b-col>
      <b-col>
        <v-textarea
          name="receiving-notes"
          :label="$t('NOTES')"
          auto-grow
          rows="1"
          prepend-icon="mdi-comment"
          v-model="tempNotes"
        ></v-textarea>
      </b-col>
    </b-row>
    <b-button class="mr-3" variant="primary" @click="updateWizardStep(3)">{{
      $t("CONTINUE")
    }}</b-button>
    <b-button
      class="mr-3"
      variant="outline-primary"
      @click="updateWizardStep(1)"
      >{{ $t("BACK") }}</b-button
    >
  </div>
</template>
<style scoped>
tr.previewTr th {
  font-size: 18px !important;
}
</style>
<script>
import { mapGetters, mapState } from "vuex";
import StockInfoPanels from "@/modules/stock/components/StockInfoPanels.vue";

import _ from "lodash";
export default {
  name: "StepStockAuditSetItems",
  inheritAttrs: false,
  props: {
    firstLoader: {
      type: Boolean,
      default: true
    },
    wizardStep: {
      type: Number,
      default: 1
    },
    globalForDate: {
      type: String,
      default: ""
    },
    notes: {
      type: String,
      default: ""
    },
    items: {
      type: Array,
      default: function() {
        return [];
      }
    },
    selectedItems: {
      type: Array,
      default: function() {
        return [];
      }
    },
    selectedItemsTotal: {
      type: Number,
      default: 0
    },
    selectedStore: {
      type: Object,
      default: function() {
        return {};
      }
    },
    supplier: {
      type: Object,
      default: function() {
        return {};
      }
    }
  },
  data() {
    return {
      panel: 0,
      search: "",
      rowsPerPageItems: [-1, 100, 20, 10],
      headers: [
        {
          text: "Item",
          align: "start",
          filterable: true,
          value: "name"
        },
        {
          text: this.$t("ITEM_TYPE"),
          value: "itemTypeName",
          sortable: true
        },
        {
          text: this.$t("ITEM_WAREHOUSE"),
          value: "warehouseName",
          sortable: true
        },
        {
          text: this.$t("LAST_STOCK"),
          value: "audit.lastStock",
          sortable: true,
          align: "right"
        },
        {
          text: this.$t("CHANGED_STOCK"),
          value: "audit.changedStock",
          sortable: true,
          align: "right"
        },
        {
          text: this.$t("INITIAL_STOCK"),
          value: "audit.initialStock",
          sortable: true,
          align: "right"
        },
        {
          text: this.$t("ORDERED_UNIT_VALUE"),
          value: "audit.orderedUnitValue",
          sortable: true,
          align: "right"
        },
        {
          text: this.$t("CONSUMED_UNIT_VALUE"),
          value: "audit.consumedUnitValue",
          sortable: true,
          align: "right"
        },
        {
          text: this.$t("IDEAL_STOCK"),
          value: "audit.idealStock",
          sortable: true,
          align: "right"
        },
        {
          text: this.$t("CURRENT_STOCK"),
          value: "audit.currentStock",
          width: "180px",
          sortable: true,
          align: "right"
        },
        {
          text: this.$t("CONSUMPTION"),
          value: "audit.consumption",
          sortable: true,
          align: "right"
        },
        {
          text: this.$t("STOCK_DIFFERENCE"),
          value: "audit.stockDifference",
          sortable: true,
          align: "right"
        },
        {
          text: this.$t("AUDIT_FOOD_COST"),
          value: "audit.foodCost",
          sortable: true,
          align: "right"
        }
      ]
    };
  },
  components: { StockInfoPanels },
  beforeMount() {},
  mounted() {},
  computed: {
    ...mapGetters(["isLoadingStock", "isLoadingSharedStore", "currentUser"]),
    ...mapState({
      errors: state => state.auth.errors
    }),
    tempFirstLoader: {
      get: function() {
        return this.firstLoader;
      },
      set: function(newValue) {
        this.$emit("update:firstLoader", newValue);
      }
    },
    tempGlobalForDate: {
      get: function() {
        return this.globalForDate;
      },
      set: function(newValue) {
        this.$emit("update:globalForDate", newValue);
      }
    },
    tempNotes: {
      get: function() {
        return this.notes;
      },
      set: function(newValue) {
        this.$emit("update:notes", newValue);
      }
    },
    tempItems: {
      get: function() {
        return this.items;
      },
      set: function(newValue) {
        this.$emit("update:items", newValue);
      }
    },
    itemTypesCount() {
      let vm = this;
      var result = _.countBy(vm.tempItems, "itemTypeName");
      return result;
    },
    sumTotalFoodCost() {
      let vm = this;
      let totalFoodCost = _.sumBy(vm.tempItems, function(item) {
        return parseFloat(item.audit.foodCost);
      });
      return this.$options.filters.formatBalance(totalFoodCost);
    }
  },
  watch: {},
  methods: {
    filterCurrentStock(item) {
      item.audit.currentStock = this.formatItemField(
        item,
        Math.abs(item.audit.currentStock)
      );
      this.updateConsumption(item);
      this.updateStockDifference(item);
      this.updateFoodCost(item);
    },
    updateConsumption(item) {
      let value =
        parseFloat(item.audit.initialStock) -
        parseFloat(item.audit.currentStock);
      item.audit.consumption = this.formatItemField(item, value);
    },
    updateStockDifference(item) {
      let value =
        parseFloat(item.audit.currentStock) - parseFloat(item.audit.idealStock);
      item.audit.stockDifference = this.formatItemField(item, value);
    },
    updateFoodCost(item) {
      let value =
        parseFloat(item.audit.consumption) *
        parseFloat(item.audit.lastStockAverageCost);
      item.audit.foodCost = this.$options.filters.formatBalance(value);
    },
    formatItemField(item, value) {
      if (item.unitNumberType == 2) {
        value = Math.round(value);
      } else {
        value = this.$options.filters.formatUnitValue(value);
      }
      return value;
    },
    updateWizardStep(wizardStep) {
      this.$emit("update:wizardStep", wizardStep);
    },
    transformUrlParams(params) {
      let apiParams =
        "?" +
        Object.keys(params)
          .map(key => key + "=" + params[key])
          .join("&");
      return apiParams;
    },
    handleInput(prop, value) {
      this.$emit("stepperInput", {
        prop: prop,
        value: value
      });
    }
  }
};
</script>
